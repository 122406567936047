import React, { ReactNode } from "react";
import classNames from "classnames";
import { Button as AriaButton, ToggleButton, ButtonProps } from "react-aria-components";
import "~/styles/button.css";
import Icon from "../Icon/Icon";

export interface IButtonProps extends ButtonProps {
    children?: ReactNode[] | ReactNode | string;
    color?: "default" | "primary" | "secondary" | "gradient" | "light";
    size?: "xs" | "sm" | "base" | "md" | "lg";
    contained?: "filled" | "tonal" | "outlined" | "text";
    elevated?: boolean;
    icon?: string;
    isDisabled?: boolean;
    iconFilled?: boolean;
    toggle?: boolean;
    fullWidth?: boolean;
    noHover?: boolean;
}

const Button: React.FunctionComponent<IButtonProps> = (props) => {
    const {
        children,
        color = "default",
        size = "base",
        className = "",
        icon,
        iconFilled = false,
        elevated = false,
        toggle = false,
        fullWidth = false,
        noHover = false,
        ...rest
    } = props;
    const _classNames = classNames({
        btn: true,
        [`btn--${size}`]: size,
        [`btn--${color}`]: color,
        [`btn--icon`]: icon,
        [`raised`]: elevated,
        [`w-full`]: fullWidth,
        ["no-hover"]: noHover,
        [`${className}`]: className,
    });
    return toggle ? (
        <ToggleButton {...rest} className={_classNames}>
            {icon ? <Icon filled={iconFilled}>{icon}</Icon> : children}
        </ToggleButton>
    ) : (
        <AriaButton {...rest} className={_classNames}>
            <>
                {icon && (
                    <Icon filled={iconFilled} size={size}>
                        {icon}
                    </Icon>
                )}
                {children && <div>{children}</div>}
            </>
        </AriaButton>
    );
};

export default Button;
