import * as React from "react";
import classNames from "classnames";
import "./icon.css";

interface IIconProps {
    children: string;
    className?: string;
    size?: "xs" | "sm" | "base" | "md" | "lg";
    filled?: boolean;
}

const Icon: React.FunctionComponent<IIconProps> = ({ children, className, size = "base", filled }) => {
    const _classNames = classNames({
        [`${className}`]: className,
        [`icon--${size}`]: size,
        filled,
    });

    return <span className={`icon material-symbols-rounded ${_classNames}`}>{children}</span>;
};

export default Icon;
